import React, { Component } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { Redirect, withRouter } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import api from '../../services/api';
import Loading from '../../components/Loading';

import './style.css';

class DiagnosticoPaciente extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: (props.match.params.id !== undefined ? props.match.params.id : null),
            isLoading: false,
            id_paciente: null,
            nome_paciente: null,
            escolaridade: null,
            nascimento: null,
            idade: "",
            data_teste: "-",
            proximo_modulo: null,
            acertos: [0, 0, 0],
            medias: [0.00, 0.00],
            indices: [0.00, 0.00]
        }

    }

    componentDidMount() {
        document.title = `Diagnóstico`;
        window.scrollTo(0, 0);

        this.carregaDados();
    }

    carregaDados() {
        this.setState({ isLoading: true });

        api.get("/teste/" + this.state.id + "/diagnostico", { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        module_complete: response.data.modulos,
                        id_paciente: response.data.paciente.id_paciente,
                        nascimento: response.data.paciente.nascimento,
                        escolaridade: response.data.paciente.escolaridade,
                        nome_paciente: response.data.paciente.nome,
                        acertos: response.data.acertos,
                        medias: response.data.medias,
                        indices: response.data.indices,
                        idade: response.data.idade,
                        data_teste: response.data.teste.created_at
                    });
                }
            })
            .catch((err) => {

            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        return (
            <div>
                <Header />

                <TopPage title={this.state.nome_paciente}
                    back={`${process.env.PUBLIC_URL}/teste/${this.state.id}/modulos`}
                    small={`Data de nascimento: ${this.state.nascimento} - ${this.state.idade}`}
                    small2={`Escolaridade: ${this.state.escolaridade}`}
                    small3={`Data do teste: ${this.state.data_teste}`}
                    icon={true}>
                    <FaUserAlt />
                </TopPage>

                <div className="container">
                    <div className="diagnostico-paciente">
                        <div className="diagnostico-title">Total de Acertos</div>
                        <div className="diagnostico-list">
                            <div className="column-1">Módulo 1</div>
                            <div className="column-2">{this.state.acertos[0]}</div>
                        </div>
                        <div className="diagnostico-list">
                            <div className="column-1">Módulo 2</div>
                            <div className="column-2">{this.state.acertos[1]}</div>
                        </div>
                        <div className="diagnostico-list">
                            <div className="column-1">Módulo 3</div>
                            <div className="column-2">{this.state.acertos[2]}</div>
                        </div>
                    </div>

                    <div className="diagnostico-paciente">
                        <div className="diagnostico-title">Média de TR de Acertos</div>
                        <div className="diagnostico-list">
                            <div className="column-1">Módulo 2</div>
                            <div className="column-2">{this.state.medias[0]}</div>
                        </div>
                        <div className="diagnostico-list">
                            <div className="column-1">Módulo 3</div>
                            <div className="column-2">{this.state.medias[1]}</div>
                        </div>
                    </div>

                    <div className="diagnostico-paciente">
                        <div className="diagnostico-title">Índice de Interferência</div>
                        <div className="diagnostico-list">
                            <div className="column-1">Interferência<br />Score Acertos</div>
                            <div className="column-2">{this.state.indices[0]}</div>
                        </div>
                        <div className="diagnostico-list">
                            <div className="column-1">Interferência<br />TR Acertos</div>
                            <div className="column-2">{this.state.indices[1]}</div>
                        </div>
                    </div>
                </div>

                {this.state.isLoading ? <Loading /> : null}

                <Footer />
            </div>
        );
    }

}

export default withRouter(DiagnosticoPaciente);