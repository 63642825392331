import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import AppPesquisador from './AppPesquisador';
import AppAdmin from './AppAdmin';

import Login from './pages/Login';
import EsqueceuSenha from './pages/EsqueceuSenha';
import AdminDashboard from './pages/AdminDashboard';
import CadastroPesquisador from './pages/CadastroPesquisador';
import CadastroPaciente from './pages/CadastroPaciente';
import PesquisadorDashboard from './pages/PesquisadorDashboard';
import PerfilPaciente from './pages/PerfilPaciente';
import TestePaciente from './pages/TestePaciente';
import DiagnosticoPaciente from './pages/DiagnosticoPaciente';
import ModuloPaciente from './pages/ModuloPaciente';
import Teste from './pages/Teste';
import CadastroAdministrador from './pages/CadastroAdministrador';

ReactDOM.render(
  <BrowserRouter>

    <Route exact path={`${process.env.PUBLIC_URL}/`}>
      <Login />
    </Route>
    <Route exact path={`${process.env.PUBLIC_URL}/restrito`}>
      <Login error="Você não tem permissão para acessar essa página. Faça login e tente novamente." />
    </Route>
    <Route exact path={`${process.env.PUBLIC_URL}/esqueceu-senha`}>
      <EsqueceuSenha />
    </Route>

    <Route exact path={[
      `${process.env.PUBLIC_URL}/dashboard`,
      `${process.env.PUBLIC_URL}/cadastrar-pesquisador`,
      `${process.env.PUBLIC_URL}/editar-pesquisador/:id`,
      `${process.env.PUBLIC_URL}/list-administradores`,
      `${process.env.PUBLIC_URL}/cadastrar-administrador`,
      `${process.env.PUBLIC_URL}/editar-administrador/:id`
    ]}>
      <AppAdmin>
        <Route exact path={`${process.env.PUBLIC_URL}/dashboard`}>
          <AdminDashboard />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/cadastrar-pesquisador`}>
          <CadastroPesquisador />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/editar-pesquisador/:id`}>
          <CadastroPesquisador />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/list-administradores`}>
          <AdminDashboard admin={true} />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/cadastrar-administrador`}>
          <CadastroAdministrador />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/editar-administrador/:id`}>
          <CadastroAdministrador />
        </Route>
      </AppAdmin>
    </Route>

    <Route exact path={[
      `${process.env.PUBLIC_URL}/cadastrar-paciente`,
      `${process.env.PUBLIC_URL}/pesquisador-dashboard`,
      `${process.env.PUBLIC_URL}/perfil-paciente/:id`,
      `${process.env.PUBLIC_URL}/editar-paciente/:id`,
      `${process.env.PUBLIC_URL}/teste/:id/modulos`,
      `${process.env.PUBLIC_URL}/teste/:id/modulo/:module_number`,
      `${process.env.PUBLIC_URL}/teste/:id/diagnostico`,
      `${process.env.PUBLIC_URL}/novo-teste/:id/modulo/:module_number`
    ]}>
      <AppPesquisador>
        <Route exact path={`${process.env.PUBLIC_URL}/pesquisador-dashboard`}>
          <PesquisadorDashboard />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/cadastrar-paciente`}>
          <CadastroPaciente />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/perfil-paciente/:id`}>
          <PerfilPaciente />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/editar-paciente/:id`}>
          <CadastroPaciente />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/teste/:id/modulos`}>
          <TestePaciente />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/teste/:id/modulo/:module_number`} component={ModuloPaciente} />
        <Route exact path={`${process.env.PUBLIC_URL}/teste/:id/diagnostico`}>
          <DiagnosticoPaciente />
        </Route>
        <Route exact path={`${process.env.PUBLIC_URL}/novo-teste/:id/modulo/:module_number`} component={Teste} />
      </AppPesquisador>
    </Route>

  </BrowserRouter >,
  document.getElementById('root')
);