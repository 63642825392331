import React, { Component } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import { Redirect, withRouter } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import ModuloTeste from '../../components/ModuloTeste';

import './style.css';
import api from '../../services/api';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';

class TestePaciente extends Component {

    timer = null;

    constructor(props) {
        super(props);

        this.state = {
            id: (props.match.params.id !== undefined ? props.match.params.id : null),
            module_complete: 0,
            isLoading: false,
            id_paciente: null,
            nome_paciente: null,
            escolaridade: null,
            nascimento: null,
            idade: "",
            data_teste: "-",
            proximo_modulo: null,
            observacao: null,
            show_diagnostico: false,
            modal_diagnostico: null,
            module_processing: [false, false, false],
            is_processing: false,
            successMessages: [],
            errorMessages: []
        }

        this.proximoModulo = this.proximoModulo.bind(this);
        this.salvaObservacao = this.salvaObservacao.bind(this);
        this.showDiagnostico = this.showDiagnostico.bind(this);
        this.checkProcessing = this.checkProcessing.bind(this);
    }

    componentDidMount() {
        document.title = `Módulos`;
        window.scrollTo(0, 0);

        this.carregaDados();

        //timer para verifica se tem algum modulo processando
        this.checkProcessing()
        this.timer = setInterval(() => { this.checkProcessing() }, 10000);
    }

    componentWillUnmount () {
        clearInterval(this.timer);
    }

    carregaDados() {
        this.setState({ isLoading: true });

        api.get("/teste/" + this.state.id + "/modulos", { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        module_complete: response.data.modulos,
                        id_paciente: response.data.paciente.id_paciente,
                        nascimento: response.data.paciente.nascimento,
                        idade: response.data.idade,
                        escolaridade: response.data.paciente.escolaridade,
                        nome_paciente: response.data.paciente.nome,
                        data_teste: response.data.teste.created_at,
                        observacao: response.data.teste.observacao
                    });
                }
            })
            .catch((err) => {

            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    proximoModulo(e) {
        e.preventDefault();

        if (this.state.module_complete < 3) {
            this.setState({
                proximo_modulo: (this.state.module_complete + 1)
            })
        }
    }

    salvaObservacao(e) {
        e.preventDefault();

        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.post("/teste/" + this.state.id + "/salva-observacao", { observacao: this.state.observacao, token: localStorage.getItem("token") })
            .then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        successMessages: response.data.messages
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    showDiagnostico(e) {
        e.preventDefault();

        if (!this.state.is_processing) {
            //redireciona para pagina de diagnostico
            this.setState({
                show_diagnostico: true
            });
        }
        else {
            window.scrollTo(0, 0);

            this.setState({
                errorMessages: ["O servidor está processando os dados do teste. Por favor, aguarde e tente novamente."]
            });
        }
    }

    checkProcessing() {
        //valida se existe alguma resposta nao processada
        api.get("/teste/" + this.state.id + "/valida-processamento", { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                if (response.data.status === "success") {
                    //seta dados e para de atualizar
                    this.setState({
                        module_processing: response.data.processing,
                        is_processing: false
                    });

                    clearInterval(this.timer);
                }
                else {
                    this.setState({
                        module_processing: response.data.processing,
                        is_processing: (response.data.processing.includes(true) ? true : false )
                    });
                }
            });
    }

    render() {
        var content = [];
        for (var i = 1; i < 4; i++) {
            if (this.state.module_complete >= i) {
                content.push(<ModuloTeste key={i} id_teste={this.state.id} modulo={`Módulo ${i}`} status={1} n_modulo={i} isLoading={this.state.module_processing[i - 1]} />);
            }
            else {
                content.push(<ModuloTeste key={i} id_teste={this.state.id} modulo={`Módulo ${i}`} status={0} n_modulo={i} isLoading={this.state.module_processing[i - 1]} />);
            }
        }

        return (
            <div>
                <Header />
                <div className="content">

                    <TopPage title={this.state.nome_paciente}
                        back={`${process.env.PUBLIC_URL}/perfil-paciente/${this.state.id_paciente}`}
                        small={`Data de nascimento: ${this.state.nascimento} - ${this.state.idade}`}
                        small2={`Escolaridade: ${this.state.escolaridade}`}
                        small3={`Data do teste: ${this.state.data_teste}`}
                        icon={true}>
                        <FaUserAlt />
                    </TopPage>

                    <div id="salvar-observacao">
                        {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                        {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}

                        {content.map((value) => {
                            return value;
                        })}

                        {this.state.module_complete >= 3 ?
                            <div>
                                <Textarea value={this.state.observacao} name="observacao" id="observacao" placeholder="Escreva uma observação sobre o teste" rows="6" onChange={(e) => { this.setState({ observacao: e.target.value }) }} ></Textarea>
                                <div className="align-buttons">
                                    {/* <Button type="button" id="proximo-modulo" name="proximo-modulo">Próximo módulo</Button> */}
                                    <Button type="button" id="ver-resultado" name="ver-resultado" onClick={this.showDiagnostico} >{this.state.is_processing ? <div className="loading-module"><BiLoaderAlt /></div> : ""} Ver Resultado</Button>
                                    <Button type="button" id="salvar" name="salvar" onClick={this.salvaObservacao} >Salvar</Button>
                                </div>
                            </div>
                            :
                            <div className="align-buttons">
                                <Button type="button" id="proximo-modulo" name="proximo-modulo" onClick={this.proximoModulo}>Próximo módulo</Button>
                            </div>
                        }

                    </div>
                </div>

                {this.modal_diagnostico}

                {this.state.isLoading ? <Loading /> : null}

                {this.state.show_diagnostico !== false ? <Redirect to={`${process.env.PUBLIC_URL}/teste/${this.state.id}/diagnostico`} /> : null}
                {this.state.proximo_modulo !== null ? <Redirect to={`${process.env.PUBLIC_URL}/novo-teste/${this.state.id}/modulo/${this.state.proximo_modulo}`} /> : null}

                <Footer />
            </div>
        );
    }

}

export default withRouter(TestePaciente);