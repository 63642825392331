import React from 'react';
import { FaPen, FaPlay, FaStop } from 'react-icons/fa';

import './style.css';

class Estimulos extends React.Component {

    audio = null;

    constructor(props) {
        super(props);

        this.state = {
            playing: false
        };

        this.play = this.play.bind(this);
        this.stop = this.stop.bind(this);

        this.audio = new Audio(this.props.audio);
    }

    componentDidMount() {
        this.audio.addEventListener('ended', () => this.setState({ playing: false }));

        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.audio.pause();
        this.audio.currentTime = 0;

        this.audio.removeEventListener('ended', () => this.setState({ playing: false }));

        this.props.onRef(undefined);
    }

    play() {
        console.log("TOCA!");

        if (this.state.playing) {
            this.setState({
                playing: false
            });

            this.audio.pause();
            this.audio.currentTime = 0;
        }
        else {
            this.setState({
                playing: true
            });

            this.audio.play();
        }
    }

    stop() {
        this.setState({
            playing: false
        });

        this.audio.pause();
        this.audio.currentTime = 0;
    }

    render() {
        return (
            <div className={`estimulo${this.props.cor !== undefined ? " estimulo-3" : ""} ${this.props.status}`}>
                <div className="nome-estimulo">{this.props.nome}</div>
                {this.props.cor !== undefined ? <div className="nome-cor">{this.props.cor}</div> : null}
                <div className="score-estimulo">{this.props.score}</div>
                <div className="tr-estimulo">{this.props.tr.toLocaleString('pt-BR', {maximumFractionDigits:3})}</div>
                <div className="status">{this.props.status}</div>

                {
                    this.props.status === "processando"
                        ?
                        null
                        :
                        <div className="acoes-estimulo">
                            <div className="edit-estimulo" onClick={this.props.edit}><FaPen /></div>
                            <div className="play-estimulo" onClick={this.props.play}>{this.state.playing ? <FaStop /> : <FaPlay />}</div>
                        </div>
                }

            </div>
        );
    }
}

export default Estimulos;