import React from 'react';

import './style.css';

class Textarea extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        var label = "";
        if (this.props.label !== undefined) {
            label = <label htmlFor={this.props.name}>{this.props.label}</label>;
        }
        
        return (
            <div className="input">
                {label}
                <textarea onChange={this.props.onChange} type={this.props.type} id={this.props.name} name={this.props.name} placeholder={this.props.placeholder} {...this.props}></textarea>
            </div>
        );
    }
}

export default Textarea;