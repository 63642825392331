import React, { Component } from 'react';
import { FaPlay, FaStop, FaPause } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import WaveSurfer from "wavesurfer.js";
import WaveSurferCursor from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
import WaveSurferRegions from "wavesurfer.js/dist/plugin/wavesurfer.regions";

import Select from '../../components/Select';
import api from '../../services/api';
import Alert from '../Alert';
import Button from '../Button';
import Loading from '../Loading';

import './style.css';

class ModalSound extends Component {

    wave = {};

    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            audio: this.props.audio,
            id: this.props.id,
            dados: null,
            modulo: 0,
            numero_pergunta: 0,
            score: 0,
            start: 0.0,
            end: 0.0,
            regions: null,
            isLoading: false,
            successMessages: [],
            errorMessages: []
        };

        this.play = this.play.bind(this);
        this.stop = this.stop.bind(this);
        this.salvar = this.salvar.bind(this);
    }

    componentDidMount() {
        this.carregarDados();
    }

    play() {
        if (this.state.playing) {
            //pausa
            this.setState({ playing: false });
            this.wave.pause();
        }
        else {
            //toca
            this.setState({ playing: true });
            this.wave.play();
        }
    }

    stop() {
        //para de tocar
        this.setState({ playing: false });
        this.wave.stop();
    }

    carregarDados() {
        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get("/get-audio/" + this.state.id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    this.wave = WaveSurfer.create({
                        container: '#sound-wave',
                        waveColor: '#06487B',
                        progressColor: '#198FE7',
                        plugins: [
                            WaveSurferCursor.create({
                                showTime: true,
                                opacity: 1,
                                customShowTimeStyle: {
                                    'background-color': '#000',
                                    color: '#fff',
                                    padding: '2px',
                                    'font-size': '10px'
                                }
                            }),
                            WaveSurferRegions.create({
                                regions: [
                                    {
                                        start: response.data.resposta.tempo_reacao,
                                        end: response.data.resposta.termino_resposta === response.data.resposta.tempo_reacao ? response.data.resposta.termino_resposta + 0.2 : response.data.resposta.termino_resposta,
                                        loop: false,
                                        color: 'rgba(25, 143, 231, 0.6)'
                                    }
                                ],
                                dragSelection: false
                            })
                        ]
                    });

                    //salva dados
                    this.setState({
                        dados: response.data.resposta,
                        modulo: response.data.resposta.etapa_teste,
                        numero_pergunta: response.data.resposta.numero_pergunta - 1,
                        start: response.data.resposta.tempo_reacao,
                        end: response.data.resposta.termino_resposta,
                        score: response.data.resposta.score
                    });

                    //carrega audio
                    this.wave.loadBlob(this.base64toBlob(response.data.blob));

                    this.wave.on('ready', () => {
                        this.wave.on('region-updated', (obj) => {
                            this.setState({
                                start: obj.start,
                                end: obj.end
                            })
                        });
                    });

                    this.wave.on('finish', () => {
                        this.setState({ playing: false });
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    base64toBlob(base64Data, contentType = '') {
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    salvar(e) {
        e.preventDefault();

        this.setState({
            isLoading: true
        });

        api.post("/salva-dados/" + this.state.id, { score: this.state.score, tempo_reacao: this.state.start, termino_resposta: this.state.end, token: localStorage.getItem("token") })
            .then((response) => {
                if (response.data.status == "success") {
                    this.setState({
                        successMessages: response.data.messages
                    });
                } else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });
    }

    render() {
        return (
            <div id="model-sound">
                <div className="content-modal">
                    <div className="close-modal" onClick={() => { this.props.close(); this.stop(); }}><IoMdClose /></div>

                    <div className="modal-sound-title">
                        <i><FaPlay /></i> Gravação Teste {this.state.modulo} - {(this.state.numero_pergunta + 1)}
                    </div>

                    {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                    {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}

                    <Select name="score" value={this.state.score} id="score" placeholder="Score" options={[[1, "Acertou"], [0, "Errou"]]} onChange={(e) => { this.setState({ score: e.target.value }) }} />

                    <div id="sound-wave"></div>
                    <div className="buttons-modal">
                        <div className="btn-play" onClick={this.play}>
                            {this.state.playing ? <FaPause /> : <FaPlay />}</div>
                        <div className="btn-stop" onClick={this.stop}><FaStop /></div>
                    </div>

                    <Button className="full" onClick={(e) => { this.salvar(e); }}>Salvar</Button>
                </div>

                {this.state.isLoading ? <Loading /> : null}
            </div>
        );
    }

}

export default ModalSound;