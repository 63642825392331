import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegTrashAlt, FaFileAlt, FaEye } from 'react-icons/fa';
import ModalRemover from '../ModalRemover';
import api from '../../services/api';
import Loading from '../../components/Loading';
import Alert from '../Alert';

import './style.css';

class ListTestes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id_paciente,
            testes: props.testes,
            remover: null,
            isLoading: false,
            successMessages: [],
            errorMessages: []
        }

        this.removerTeste = this.removerTeste.bind(this);
        this.remover = this.remover.bind(this);
    }

    componentWillMount() {
        //recupera cadastro de pesquisadores
    }

    componentWillUpdate(props, state) {
        if (props.testes !== this.state.testes) {
            this.setState({
                testes: props.testes
            });
        }
    }

    carregarDados() {
        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get("/get-paciente/" + this.state.id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        testes: response.data.testes
                    });
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    removerTeste(id) {
        this.setState({
            remover:
                <ModalRemover remover={() => { this.remover(id) }} onClick={() => { this.setState({ remover: null }) }} />
        });
    }

    remover(id) {
        //ativa loading
        this.setState({
            remover: null,
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.delete("/remover-teste/" + id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        successMessages: response.data.messages
                    });

                    //recarrega lista
                    this.carregarDados();
                }
                else {
                    this.setState({
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            })
            .finally(() => {
                window.scrollTo(0, 0);
            });
    }

    render() {
        var loop = [];

        this.state.testes.map((value, index) => {
            loop.push(
                <div className="paciente-body" key={`paciente-` + value.id_teste}>
                    <div className="icon-paciente"><FaFileAlt /></div>
                    <Link to={`${process.env.PUBLIC_URL}/teste/${value.id_teste}/modulos`} className="nome-paciente">
                        {value.created_at}
                        <i><FaEye /></i>
                        <div className="dados-teste">
                            Interferência Score Acertos: {value.interferencia_score_acertos}<br />Interferência TR Acertos: {value.interferencia_tr_acertos}
                        </div>
                    </Link>

                    <button className="btn-remover-paciente" onClick={() => { this.removerTeste(value.id_teste); }}><FaRegTrashAlt /></button>
                </div>
            );
        });

        return (
            <content id="list-pacientes">
                <div className="container">

                    <div className="list">
                        {this.state.successMessages.length > 0 ? <Alert tipo="success" messages={this.state.successMessages} /> : null}
                        {this.state.errorMessages.length > 0 ? <Alert tipo="error" messages={this.state.errorMessages} /> : null}

                        {loop.map((value, index) => {
                            return value;
                        })}

                        {
                            loop.length == 0 ? <div className="nenhum-registro">Nenhum teste encontrado.</div> : ""
                        }

                    </div>
                </div>

                {this.state.remover}

                {this.state.isLoading ? <Loading /> : null}
            </content>
        );
    }
}

export default ListTestes;