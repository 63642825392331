import React from 'react';
import { FaUser } from 'react-icons/fa';

import './style.css';
import { Link, Redirect } from 'react-router-dom';
import host from '../../services/host';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = { menuActive: false, redirectLogin: false };

        this.menu = this.menu.bind(this);
        this.onSelectMenu = this.onSelectMenu.bind(this);
        this.sair = this.sair.bind(this);
    }

    menu() {
        if (!this.state.menuActive) {
            this.setState({ menuActive: true });
        } else {
            this.setState({ menuActive: false });
        }
    }

    onSelectMenu() {
        this.setState({ menuActive: false });
    }

    sair(e) {
        e.preventDefault();

        //remove dados do login
        localStorage.clear();

        //vai para o login
        this.setState({redirectLogin: true});
    }

    render() {
        return (
            <header id="header">
                <div className="top-bar">
                    <div className="container">
                        <div className="logo">
                            <div className="logo-text">Stroop</div>
                            <div className="logo-version">v1.0.0</div>
                        </div>

                        <div className="btn-user" onClick={this.menu}>
                            <FaUser />
                        </div>


                        {localStorage.getItem("tipo") == 1
                            ?
                            <div className={`dropdown ` + (this.state.menuActive ? `active` : ``)}>
                                <Link to={`${process.env.PUBLIC_URL}/dashboard`} onClick={this.onSelectMenu}>Pesquisadores</Link>
                                <Link to={`${process.env.PUBLIC_URL}/list-administradores`} onClick={this.onSelectMenu}>Administradores</Link>
                                <a href={`${host}/export-xls?token=${localStorage.getItem("token")}`} target="_blank" onClick={this.onSelectMenu}>Exportar XLS de testes</a>
                                <a href="#" onClick={this.sair}>Sair</a>
                            </div>
                            :
                            <div className={`dropdown ` + (this.state.menuActive ? `active` : ``)}>
                                <Link to={`${process.env.PUBLIC_URL}/pesquisador-dashboard`} onClick={this.onSelectMenu}>Pacientes</Link>
                                <a href={`${host}/export/pesquisador/${localStorage.getItem("id_pesquisador")}?token=${localStorage.getItem("token")}`} target="_blank" onClick={this.onSelectMenu}>Exportar XLS de testes</a>
                                <a href="#" onClick={this.sair}>Sair</a>
                            </div>
                        }

                    </div>
                </div>

                <div className="heightHeader"></div>
                
                {this.state.redirectLogin ? <Redirect to={`${process.env.PUBLIC_URL}/`} /> : null }
            </header>
        );
    }
}

export default Header;