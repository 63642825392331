import React from 'react';
import Button from '../Button';
import { IoMdClose } from 'react-icons/io';

import './style.css';

class ModalRemover extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            onlyMessage: props.onlymessage !== undefined ? true : false,
            message: props.message !== undefined ? props.message : "Deseja realmente remover?"
        }
    }

    render() {
        return (
            <div id="modal-remover">
                <div className="content-modal-remover">
                    <div className="close-modal-remover" onClick={this.props.onClick}><IoMdClose /></div>
                    <div className="title-remover">
                        {this.state.message}
                    </div>
                    {this.state.onlyMessage
                        ?
                        null
                        :
                        <div className="align-buttons-remover">
                            <Button className="full" onClick={this.props.remover}>Sim</Button>
                            <Button className="full" onClick={this.props.onClick}>Não</Button>
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default ModalRemover;