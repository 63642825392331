import React from 'react';
import { Link } from 'react-router-dom';
import { FaCheck, FaFileAlt, FaEye } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';

import './style.css';

class ModuloTeste extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="status-teste">
                {(this.props.status == 1)?<div className="icon-status complete"><FaCheck /></div>:<div className="icon-status">?</div>}
                <div className="modulo">{this.props.modulo}</div>
                {(this.props.isLoading !== false) ? <div className="loading-module"><BiLoaderAlt /></div> : ""}
                {(this.props.status == 1)?<Link to={`${process.env.PUBLIC_URL}/teste/${this.props.id_teste}/modulo/${this.props.n_modulo}`} className="btn-ver"><FaEye /></Link>:""}
            </div>
        );
    }
}

export default ModuloTeste;