import React from "react";
import "./style.css";

export default class Alert extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log(Array.from(this.props.messages));
    }

    render() {
        return (
            <div className={`alert${this.props.tipo !== undefined ? " " + this.props.tipo : ""}`}>
                {Array.from(this.props.messages).map((value, index) => {
                    return <div key={index}>- {value}</div>
                })}
            </div>
        );
    }

}