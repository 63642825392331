import React from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import Input from '../Input';

import './style.css';

class Select extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            outros: false,
            value: this.props.value
        }

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate() {
        if (this.state.value !== this.props.value) {
            this.setState({
                value: this.props.value
            }, () => {
                this.containValueInSelect()
            });
        }
    }

    onChange (e) {
        e.preventDefault();

        //mostra campo de outros
        if (e.target.value === "Outros") {
            this.setState({
                outros: true
            })
        }
        else {
            this.setState({
                outros: null
            })
        }

        this.props.onChange(e);
    }

    onChangeOutros (e) {
        e.preventDefault();

        this.setState({
            value: e.target.value
        })

        this.props.onChange(e);
    }

    /**
     * verifica se value tem no select (se não abre campo outro) 
     */
    containValueInSelect () {
        var contain = false;
        if (this.state.value !== "") {
            for(var i = 0; i < this.props.options.length; i++) {
                if (this.props.options[i][0] == this.state.value) {
                    contain = true;
                }
            }
        }
        else {
            contain = true;
        }

        if (!contain) {
            this.setState({
                outros: true
            })
        }

        return contain;
    }

    render() {

        var label = "";
        if (this.props.label !== undefined) {
            label = <label htmlFor={this.props.name}>{this.props.label}</label>
        }

        return (
            <div className="input">
                {label}
                <select defaultValue="-1" value={this.state.outros ? "Outros" : (this.state.value === "" ? "-1" : this.state.value)} onChange={(e) => {this.onChange(e)}} id={this.props.name} name={this.props.name}>
                    <option value="-1" disabled>{this.props.placeholder}</option>

                    {this.props.options.map((value, index) => {
                        return <option key={index} value={value[0]} selected={value[0] === "Outros" && this.state.outros ? true : !this.state.outros ? this.props.value : false }>{value[1]}</option>
                    })}
                </select>

                <div className="select-icon"><IoMdArrowDropdown /></div>

                {this.state.outros ? <Input placeholder={this.props.placeholder} type="text" id={this.props.name + "-outros"} name={this.props.name + "-outros"} value={this.state.value} onChange={(e) => { this.onChangeOutros(e) }} /> : null}
            </div>
        );
    }
}

export default Select;