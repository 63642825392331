import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopPage from '../../components/TopPage';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ListTestes from '../../components/ListTestes';
import Loading from '../../components/Loading';
import host from '../../services/host';

class PerfilPaciente extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: (props.match.params.id !== undefined ? props.match.params.id : null),
            nome: "",
            nascimento: "",
            idade: "",
            escolaridade: "",
            testes: [],
            ultimo: "",
            successMessages: [],
            errorMessages: [],
            isLoading: false,
            id_teste: null,
            token: localStorage.getItem("token")
        }

        this.novoTeste = this.novoTeste.bind(this);
    }

    componentDidMount() {
        document.title = `Perfil`;
        window.scrollTo(0, 0);

        if (this.state.id !== null) {
            this.carregarDados();
        }
    }

    carregarDados() {
        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get("/get-paciente/" + this.state.id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        nome: response.data.dados.nome,
                        idade: response.data.idade,
                        nascimento: response.data.dados.nascimento,
                        escolaridade: response.data.dados.escolaridade,
                        testes: response.data.testes,
                        ultimo: response.data.ultimo
                    });
                }
                else {
                    this.setState({
                        id: null,
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    id: null,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    novoTeste(e) {
        e.preventDefault();

        //ativa loading
        this.setState({
            isLoading: true,
            successMessages: [],
            errorMessages: []
        });

        api.get("/novo-teste/" + this.state.id, { params: { token: localStorage.getItem("token") } })
            .then((response) => {
                //esconde loading
                this.setState({
                    isLoading: false
                });

                if (response.data.status === "success") {
                    //mostra mensagem
                    this.setState({
                        id_teste: response.data.id_teste
                    });
                }
                else {
                    this.setState({
                        isLoading: false,
                        errorMessages: response.data.messages
                    });
                }
            })
            .catch((err) => {
                //esconde loading
                this.setState({
                    isLoading: false,
                    errorMessages: ["Ocorreu um erro inexperado. Por favor, tente novamente."]
                });
            });
    }

    render() {
        return (
            <div>
                <Header />

                <div className="content">
                    <TopPage title={this.state.nome}
                        back={`${process.env.PUBLIC_URL}/pesquisador-dashboard`}
                        small={`Data de nascimento: ${this.state.nascimento} - ${this.state.idade}`}
                        small3={`Escolaridade: ${this.state.escolaridade}`}
                        icon={true} editpaciente={`${process.env.PUBLIC_URL}/editar-paciente/${this.state.id}`}
                        exportxls={`${host}/export/paciente/${this.state.id}?token=${localStorage.getItem("token")}`}
                        addteste={(e) => { this.novoTeste(e) }}><FaUserAlt /></TopPage>
                    <ListTestes testes={this.state.testes} id_paciente={this.state.id} />
                </div>

                {this.state.id == null ? <Redirect to={`${process.env.PUBLIC_URL}/pesquisador-dashboard`} /> : null}

                {this.state.id_teste !== null ? <Redirect to={`${process.env.PUBLIC_URL}/novo-teste/${this.state.id_teste}/modulo/1`} /> : null}

                <Footer />

                {this.state.isLoading ? <Loading /> : null}
            </div>
        );
    }

}

export default withRouter(PerfilPaciente);